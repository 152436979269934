<!--
* 创建人：罗兴
* 日 期：
* 描 述：勤工助学学生岗位
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">我的岗位</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button size="small" icon="el-icon-plus" @click="showGWList"
          >申请新岗位</el-button
        >
        <el-button size="small" icon="el-icon-box" @click="showApplyList"
          >查看申请记录</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="gwmc" label="岗位名称"> </el-table-column>
        <el-table-column prop="bmmc" label="单位"> </el-table-column>
        <el-table-column prop="xn" label="学年"> </el-table-column>
        <el-table-column prop="dgsj" label="到岗时间">
          <template slot-scope="scope">
            {{ dateFormat2(scope.row.dgsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="lgsj" label="离岗时间">
          <template slot-scope="scope">
            {{ dateFormat2(scope.row.lgsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="zt" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.zt == 0" type="danger">离岗</el-tag>
            <el-tag v-if="scope.row.zt == 1" type="success">在岗</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
      <!-----------------------岗位列表-------------------------->
      <el-dialog
        title="岗位列表"
        :visible.sync="diaGWListVisible"
        width="70%"
        :close-on-click-modal="false"
        top="5vh"
      >
        <el-scrollbar style="height: 650px" class="myscrollbar">
          <el-row :gutter="20" style="margin-bottom: 10px">
            <el-col :span="5">
              <el-select
                v-model="bmbh"
                size="small"
                clearable
                placeholder="请选择单位"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in dwList"
                  :key="index"
                  :label="item.bmmc"
                  :value="item.bmbh"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input
                placeholder="请输入岗位名称"
                v-model="gwmc"
                class="input-with-select"
                size="small"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="getgwlist"
                ></el-button>
              </el-input>
            </el-col>
          </el-row>
          <el-empty v-if="gwlist.length == 0" description="暂无内容"></el-empty>
          <el-row :gutter="20">
            <el-col
              :span="6"
              v-for="(item, index) in gwlist"
              :key="index"
              @click.native="selectgw(item.id)"
              style="margin-top: 10px"
            >
              <el-card
                class="box-card"
                :shadow="gwid == item.id ? 'always' : 'never'"
              >
                <div slot="header" class="clearfix">
                  <span style="font-weight: 700">{{ item.gwmc }}</span>
                </div>
                <div class="itemgroup">
                  <div class="item">
                    <span class="colorgray">工作部门：</span
                    ><span>{{ item.bmmc }}</span>
                  </div>
                  <div class="item">
                    <span class="colorgray">工作时间：</span
                    ><span>{{
                      dateFormat2(item.gzkssj) + '-' + dateFormat2(item.gzjssj)
                    }}</span>
                  </div>
                  <div class="item">
                    <span class="colorgray">负责人：</span
                    ><span>{{ item.gwfzr }}</span>
                  </div>
                  <div class="item">
                    <span class="colorgray">联系电话：</span
                    ><span>{{ item.lxdh }}</span>
                  </div>
                  <div class="item">
                    <span class="colorgray">申请人数：</span
                    ><span>{{ item.sqrs }}</span>
                  </div>
                  <div class="item">
                    <span class="colorgray">通过人数/需求人数：</span
                    ><span>{{ item.tgrs + '/' + item.xqrs }}</span>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-scrollbar>
        <div slot="footer" class="dialog-footer">
          <el-button @click="diaGWListVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitApply">提交申请</el-button>
        </div>
      </el-dialog>
      <el-drawer
        title="岗位详情"
        :visible.sync="drawer"
        direction="rtl"
        size="35%"
      >
        <el-form :model="forminfo" style="padding: 20px">
          <div class="form-item-group">
            <i class="fa fa-window-restore"></i>
            <div>基础信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学年" label-width="120px">
                <el-input
                  v-model="forminfo.XN"
                  autocomplete="off"
                  placeholder="学年"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单位" label-width="120px">
                <el-select
                  v-model="forminfo.BMBH"
                  placeholder="请选择单位"
                  style="display: block"
                  readonly
                >
                  <el-option
                    v-for="(item, index) in dwList"
                    :key="index"
                    :label="item.bmmc"
                    :value="item.bmbh"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="岗位名称" label-width="120px">
                <el-input
                  v-model="forminfo.GWMC"
                  autocomplete="off"
                  placeholder="岗位名称"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="需求人数" label-width="120px">
                <el-input-number
                  v-model="forminfo.XQRS"
                  :min="1"
                  label="申请人数"
                  readonly
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="申请开始时间" label-width="120px">
                <el-date-picker
                  v-model="forminfo.SQKSSJ"
                  type="date"
                  align="right"
                  placeholder="选择日期"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                  readonly
                >
                  >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请结束时间" label-width="120px">
                <el-date-picker
                  v-model="forminfo.SQJSSJ"
                  type="date"
                  align="right"
                  placeholder="选择日期"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                  readonly
                >
                  >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="工作开始时间" label-width="120px">
                <el-date-picker
                  v-model="forminfo.GZKSSJ"
                  type="date"
                  align="right"
                  placeholder="选择日期"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                  readonly
                >
                  >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作结束时间" label-width="120px">
                <el-date-picker
                  v-model="forminfo.GZJSSJ"
                  type="date"
                  align="right"
                  placeholder="选择日期"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                  readonly
                >
                  >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="岗位描述" label-width="120px">
            <el-input
              v-model="forminfo.GWMS"
              autocomplete="off"
              type="textarea"
              :rows="5"
              placeholder="记录谈话/谈心或心理辅导的原因、内容和发现的问题、拟采取措施等"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="岗位要求" label-width="120px">
            <el-input
              v-model="forminfo.GWYQ"
              autocomplete="off"
              type="textarea"
              :rows="5"
              placeholder="栏请注明岗位对学生的具体要求，如性别、专业、特长等"
              readonly
            ></el-input>
          </el-form-item>
          <div class="form-item-group">
            <i class="fa fa-window-restore"></i>
            <div>联系信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="负责人" label-width="120px">
                <el-input
                  v-model="forminfo.GWFZR"
                  autocomplete="off"
                  placeholder="岗位负责人"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" label-width="120px">
                <el-input
                  v-model="forminfo.LXDH"
                  autocomplete="off"
                  placeholder="联系电话"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button
            type="primary"
            style="width: 100%; margin-top: 20px"
            @click="showSQForm"
            >提交申请</el-button
          >
        </el-form>
      </el-drawer>
      <el-drawer
        :before-close="closexsDra"
        title="岗位申请"
        :visible.sync="sqdrawer"
        direction="rtl"
        size="35%"
      >
        <el-form
          :model="info"
          ref="formRef"
          :rules="infoRules"
          style="padding: 20px"
        >
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in info.spjl"
              :key="index"
              :timestamp="dateFormat2(item.cjsj)"
              placement="top"
            >
              <el-card>
                <h4 v-if="item.spzt == 10 || item.spzt == 20">
                  单位审批{{ item.spzt == 100 ? '通过' : '拒绝' }}
                </h4>
                <h4 v-if="item.spzt == 11 || item.spzt == 12">
                  学工审批{{ item.spzt == 1100 ? '通过' : '拒绝' }}
                </h4>
                <p>审批意见：{{ item.spyj }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <div class="form-item-group">
            <i class="fa fa-window-restore"></i>
            <div>申请信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系电话" label-width="130px" prop="LXDH">
                <el-input
                  v-model="info.LXDH"
                  autocomplete="off"
                  placeholder="联系电话"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="银行卡号" label-width="130px" prop="YHKH">
                <el-input
                  v-model="info.YHKH"
                  autocomplete="off"
                  placeholder="银行卡号"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="家庭经济情况" label-width="130px" prop="JTJJQK">
            <el-input
              v-model="info.JTJJQK"
              autocomplete="off"
              type="textarea"
              :rows="5"
              placeholder="家庭经济情况"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="奖惩情况及特长"
            label-width="130px"
            prop="JCQKJTC"
          >
            <el-input
              v-model="info.JCQKJTC"
              autocomplete="off"
              type="textarea"
              :rows="5"
              placeholder="奖惩情况及特长"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="130px">
            <el-input
              v-model="info.BZ"
              autocomplete="off"
              type="textarea"
              :rows="5"
              placeholder="备注"
            ></el-input>
          </el-form-item>
          <el-button
            type="primary"
            style="width: 100%; margin-top: 20px"
            @click="saveForm"
            v-if="info.SPZT == 0"
            >提交申请</el-button
          >
          <el-button
            type="danger"
            style="width: 100%; margin-top: 20px; margin-left: 0"
            v-if="info.SPZT == 0 && info.ID != ''"
            @click="deletesq"
            >撤销申请</el-button
          >
        </el-form>
      </el-drawer>

      <!---岗位申请列表--->
      <!-----------------------岗位列表-------------------------->
      <el-dialog
        title="已申请岗位"
        :visible.sync="diaSQListVisible"
        width="70%"
        :close-on-click-modal="false"
        top="5vh"
      >
        <el-scrollbar style="height: 650px" class="myscrollbar">
          <el-row :gutter="20" style="margin-bottom: 10px">
            <el-col :span="5">
              <el-select
                v-model="sqbmbh"
                size="small"
                clearable
                placeholder="请选择单位"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in dwList"
                  :key="index"
                  :label="item.bmmc"
                  :value="item.bmbh"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input
                placeholder="请输入岗位名称"
                v-model="sqgwmc"
                class="input-with-select"
                size="small"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="getysqgw"
                ></el-button>
              </el-input>
            </el-col>
          </el-row>
          <el-empty v-if="sqlist.length == 0" description="暂无内容"></el-empty>
          <el-row :gutter="20">
            <el-col
              :span="6"
              v-for="(item, index) in sqlist"
              :key="index"
              style="margin-top: 10px"
            >
              <el-card
                class="box-card"
                :shadow="sqgwid == item.id ? 'always' : 'never'"
                @click.native="selectsqgw(item.id)"
              >
                <div slot="header" class="clearfix">
                  <span style="font-weight: 700">{{ item.gwmc }}</span>
                </div>
                <div class="itemgroup">
                  <div class="item">
                    <span class="colorgray">工作部门：</span
                    ><span>{{ item.bmmc }}</span>
                  </div>
                  <div class="item">
                    <span class="colorgray">负责人：</span
                    ><span>{{ item.gwfzr }}</span>
                  </div>
                  <div class="item">
                    <span class="colorgray">联系电话：</span
                    ><span>{{ item.gwlxdh }}</span>
                  </div>
                  <div class="item">
                    <span class="colorgray">审批状态：</span>
                    <span v-if="item.step == 1">{{
                      item.spzt == 0
                        ? '待审批'
                        : item.spzt == 10
                        ? '单位审批通过'
                        : '单位审批不通过'
                    }}</span>
                    <span v-if="item.step == 2">{{
                      item.spzt == 10
                        ? '单位审批通过'
                        : item.spzt == 11
                        ? '学工审批通过'
                        : '学工审批不通过'
                    }}</span>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-scrollbar>
      </el-dialog>
    </div>
    <!------------------添加 修改 -------------->
  </div>
</template>
<!-- javascript脚本-->
<script>
import {
  getxsgwpagelist,
  getallowpagelist,
  getgwglformdata,
  GWSQSave,
  getxssqpagelist,
  getxssqdetail,
  deletexssq,
} from '../../api/qgzx'
import { getAuthorizeButtonColumnList } from '../../api/system'
import { getAllBM } from '../../api/jcxx'
import moment from 'moment'
export default {
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        value = Number(value) // 转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
          // 判断是否为数字
          value = value.toString() // 转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            // 判断是否为11位手机号
            callback(new Error('手机号码格式如:138xxxx8754'))
          } else {
            callback()
          }
        } else {
          callback(new Error('请输入电话号码'))
        }
      }
    }
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      forminfo: {
        ID: '',
        XN: '',
        BMBH: '',
        GWMC: '',
        XQRS: 0,
        GWFZR: '',
        LXDH: '',
        SQKSSJ: null,
        SQJSSJ: null,
        GZKSSJ: null,
        GZJSSJ: null,
        GWMS: '',
        GWYQ: '',
      },
      info: {
        ID: '',
        GWID: '',
        XH: '',
        LXDH: '',
        YHKH: '',
        JTJJQK: '',
        JCQKJTC: '',
        BZ: '',
        SPZT: 0,
        STEP: 0,
        spjl: [],
      },
      infoRules: {
        LXDH: [
          { required: true, message: '请填写电话', trigger: 'blur' },
          { validator: isMobileNumber, trigger: 'blur' },
        ],
        YHKH: [
          { required: true, message: '请填写银行卡号', trigger: 'change' },
        ],
        JTJJQK: [
          { required: true, message: '请填写家庭经济情况', trigger: 'blur' },
        ],
        JCQKJTC: [
          { required: true, message: '请填写奖惩情况及特长', trigger: 'blur' },
        ],
      },
      diaGWListVisible: false,
      gwlist: [],
      dwList: [],
      gwmc: '',
      bmbh: '',
      gwid: '',
      drawer: false,
      sqdrawer: false,
      sqlist: [],
      diaSQListVisible: false,
      sqgwmc: '',
      sqbmbh: '',
      sqgwid: '',
    }
  },
  watch: {},
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getxsgwpagelist({
        queryJson: JSON.stringify({}),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getgwlist() {
      getallowpagelist({
        queryJson: JSON.stringify({ gwmc: this.gwmc, bmbh: this.bmbh }),
      }).then((res) => {
        if (res.code === 200) {
          this.gwlist = res.data
        }
      })
    },
    showGWList() {
      getAllBM().then((res) => {
        if (res.code === 200) {
          this.dwList = res.data
        }
      })
      this.getgwlist()
      this.diaGWListVisible = true
    },
    submitApply() {
      if (!this.gwid) {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.sqdrawer = true
      }
    },
    showApplyList() {
      this.getysqgw()
      getAllBM().then((res) => {
        if (res.code === 200) {
          this.dwList = res.data
        }
      })
      this.diaSQListVisible = true
    },
    selectgw(id) {
      this.gwid = id
      getgwglformdata({ id: this.gwid }).then((res) => {
        if (res.code === 200) {
          this.forminfo.ID = res.data.id
          this.forminfo.XN = res.data.xn
          this.forminfo.BMBH = res.data.bmbh
          this.forminfo.GWMC = res.data.gwmc
          this.forminfo.XQRS = res.data.xqrs
          this.forminfo.GWFZR = res.data.gwfzr
          this.forminfo.LXDH = res.data.lxdh
          this.forminfo.SQKSSJ = res.data.sqkssj
          this.forminfo.SQJSSJ = res.data.sqjssj
          this.forminfo.GZKSSJ = res.data.gzkssj
          this.forminfo.GZJSSJ = res.data.gzjssj
          this.forminfo.GWMS = res.data.gwms
          this.forminfo.GWYQ = res.data.gwyq
          this.dialogFormVisible = true
        } else {
          this.$message.error(res.message)
        }
      })
      this.drawer = true
    },
    showSQForm() {
      this.drawer = false
      this.sqdrawer = true
    },
    // 保存信息
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.info.GWID = this.gwid
          GWSQSave(this.info).then((res) => {
            if (res.code === 200) {
              this.$message.success('保存成功！')
              this.sqdrawer = false
              this.getgwlist()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    closexsDra() {
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
      this.sqdrawer = false
    },
    // 获取已申请岗位
    getysqgw() {
      getxssqpagelist({
        queryJson: JSON.stringify({ gwmc: this.sqgwmc, bmbh: this.sqbmbh }),
      }).then((res) => {
        if (res.code === 200) {
          this.sqlist = res.data
        }
      })
    },
    selectsqgw(id) {
      this.sqgwid = id
      getxssqdetail({ id: id }).then((res) => {
        if (res.code === 200) {
          this.info.ID = res.data.id
          this.info.GWID = res.data.gwid
          this.info.XH = res.data.xh
          this.info.LXDH = res.data.lxdh
          this.info.YHKH = res.data.yhkh
          this.info.JTJJQK = res.data.jtjjqk
          this.info.JCQKJTC = res.data.jcqkjtc
          this.info.BZ = res.data.bz
          this.info.SPZT = res.data.spzt
          this.info.STEP = res.data.step
          this.info.spjl = res.data.spjl
          this.sqdrawer = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
    deletesq() {
      this.$confirm('是否确认撤销?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deletexssq({ id: this.sqgwid }).then((res) => {
            if (res.code === 200) {
              this.sqdrawer = false
              this.getysqgw()
              this.$message.success('撤销成功！')
            } else {
              this.$message.success(res.message)
            }
          })
        })
        .catch(() => {})
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.el-card >>> .el-card__header {
  padding: 10px 20px;
  font-size: 16px;
}
.el-card >>> .el-card__body {
  padding: 10px 20px;
}
.item {
  padding: 5px 0;
}
.colorgray {
  color: #ccc;
}
</style>
